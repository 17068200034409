<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterMain"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTable()"
    >
      <template #table>
        <b-table
          ref="refQuickMessageTable"
          small
          show-empty
          responsive="sm"
          :fields="fields"
          :items="myProvider"
          :busy.sync="isBusy"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(sms)="data">
            <change-sms :SmsData="data.item.sms" />
          </template>

          <template #cell(created_by)="data">
            <span>{{ data.item.created_by }}</span
            ><br />
            <span>{{ data.item.created_at | myGlobal }}</span>
          </template>

          <template #cell(updated_by)="data">
            <span>{{ data.item.updated_by }}</span
            ><br />
            <span>{{ data.item.updated_at | myGlobal }}</span>
          </template>

          <template #cell(action)="data">
            <b-dropdown
              boundary="viewport"
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                @click="openCreateQuickModal(data.item)"
                variant="warning"
              >
                <feather-icon icon="Edit2Icon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                variant="danger"
                @click="deleteQuickSms(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <create-quick-sms-modal
      v-if="createQuickModal"
      :quick-item="quickItem"
      @refreshTable="refreshTable"
      @closeModal="closeCreateQuickModal"
    />
  </div>
</template>

<script>
import MessageService from "@/views/ce-digital/sub-modules/settings/views/messages/service/ce-set-messages.service";
import fields from "@/views/ce-digital/sub-modules/settings/views/messages/components/quick-messages/data/fields.quick-messages.data";
import CreateQuickSmsModal from "@/views/ce-digital/sub-modules/settings/views/messages/components/quick-messages/modals/CreateQuickSmsModal.vue";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms";

export default {
  name: "QuickMessages",
  components: { ChangeSms, CreateQuickSmsModal },
  data() {
    return {
      filters: [],
      totalRows: 0,
      filterMain: {
        type: "input",
        inputType: "text",
        placeholder: "Search Client...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      fields,
      quickSmsData: [],
      createQuickModal: false,
      quickItem: { id: null },
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    openCreateQuickModal(item) {
      this.quickItem = item;
      this.createQuickModal = true;
    },
    closeCreateQuickModal() {
      this.createQuickModal = false;
    },
    async deleteQuickSms(item) {
      try {
        const swal = await this.showConfirmSwal();
        if (swal.isConfirmed) {
          const params = {
            id: item.id,
          };
          const response = await MessageService.deleteQuickSms(params);
          if (response.status === 200) {
            this.refreshTable();
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    refreshTable() {
      this.$refs["refQuickMessageTable"].refresh();
    },
    async myProvider(ctx) {
      const params = {
        quick_sms_text: this.filterMain.model,
        module_id: 21,
        order_by: ctx.sortBy === "" ? "qs.created_at" : ctx.sortBy,
        order: ctx.sortDesc ? "desc" : "asc",
        per_page: ctx.perPage,
        page: ctx.currentPage,
      };
      const { data } = await MessageService.getQuickSmsAdvanced(params);
      const {
        data: quickSmsData,
        total,
        from,
        current_page,
        per_page,
        last_page,
        to,
      } = data;
      this.quickSmsData = quickSmsData;
      this.paginate.currentPage = current_page;
      this.startPage = from;
      this.paginate.perPage = per_page;
      this.nextPage = this.startPage + 1;
      this.endPage = last_page;
      this.totalRows = total;
      this.toPage = to;
      return this.quickSmsData || [];
    },
  },
};
</script>

<style scoped>
</style>

