export default [
    {
        key: "title",
        label: "title",
        thStyle: 'width: 30%',
    },
    {
        key: "sms",
        label: "sms",
    },
    {
        key: "created_by",
        label: "created by",
    },
    {
        key: 'module_name',
        label: 'Module',
    },
    {
        key: "updated_by",
        label: "updated by",
    },
    {
        key: "action",
        label: "actions",
    },
]
